import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "./privacy-policy.scss"
import stringToHTML from "../../utils/stringToHTML.js"

const PrivacyPolicy = ({ intl, location }) => (
  <Layout prefooter="2" menuSelector={location?.state?.menuSelector || 1}>
    <SEO title="PrivacyTerms" />
    <div className="privacy-policy">
      <div className="privacy-policy-header">
        <h1 className="privacy-policy-title">
          {stringToHTML(intl.formatMessage({ id: "privacy-policy-title" }))}
        </h1>
      </div>
      <div className="privacy-policy-content">
        <div
          dangerouslySetInnerHTML={{
            __html: stringToHTML(
              intl.formatMessage({ id: "privacy-policy-content" })
            ),
          }}
        >
        </div>
      </div>
    </div>
  </Layout>
)

export default injectIntl(PrivacyPolicy)
