import {
  lPhantEmail,
  lPhantTelephone,
  lPhantEmailDisplay,
  lPhantURL,
  lPhantURLDisplay,
  lPhantSignUpURL,
  lPhantSignUpURLDisplay,
  lPhantSignInURL,
  lPhantSignInURLDisplay,
  lPhantPasswordRecoveryURL,
  lPhantPasswordRecoveryURLDisplay,
  lPhantCapMarketURL,
  lPhantCapMarketURLDisplay,
  minMXN,
  maxMXN,
} from "./variables"

const stringToHTML = string => {
  const URL = `<a target="_blank" rel="noopener noreferrer external" href=${lPhantURL}>${lPhantURLDisplay}</a>`
  const email = `<a href = "mailto: ${lPhantEmail}">${lPhantEmailDisplay}</a>`
  const URLSignUp = `<a target="_blank" rel="noopener noreferrer external" href=${lPhantSignUpURL}>${lPhantSignUpURLDisplay}</a>`
  const URLSignIn = `<a target="_blank" rel="noopener noreferrer external" href=${lPhantSignInURL}>${lPhantSignInURLDisplay}</a>`
  const URLPasswordRecovery = `<a target="_blank" rel="noopener noreferrer external" href=${lPhantPasswordRecoveryURL}>${lPhantPasswordRecoveryURLDisplay}</a>`
  const URLCapMarket = `<a target="_blank" rel="noopener noreferrer external" href=${lPhantCapMarketURL}>${lPhantCapMarketURLDisplay}</a>`
  
  let convertString = string
    .replace(/_URL /g, URL)
    .replace(/_URLSignUp /g, URLSignUp)
    .replace(/_URLSignIn /g, URLSignIn)
    .replace(/_emailUS /g, email)
    .replace(/_phoneUS /g, lPhantTelephone)
    .replace(/_passwordRecovery /g, URLPasswordRecovery)
    .replace(/_URLCapMarket /g, URLCapMarket)
    .replace(/_minMXN /g, minMXN)
    .replace(/_maxMXN /g, maxMXN);
    return convertString
}
export default stringToHTML
